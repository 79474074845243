import { get } from '@/scripts/http';
import { Decrypt } from "@/scripts/utils";
import { GetRECMaterialsParam, GetClsRoomDatasParam, DeleteMaterialParam, GetMaterialByRTIDParam } from "./type/record-type"

const ORGTOKEN = Decrypt(localStorage.getItem("OrgToken"));

export default {
    ServiceURL: `/${ORGTOKEN}/V4/AssetM`,
    //获取录播资源列表
    async GetRECMaterials(data: GetRECMaterialsParam) {
        const res = await get(`${this.ServiceURL}/GetRECMaterials`, data);
        return res;
    },
    //获取教室类型
    async GetALLClsRoomTypes() {
        const res = await get(`/${ORGTOKEN}/V4/FJRH.Campus/ClsRoomService/GetALLClsRoomTypes`, {});
        return res;
    },
    //获取教室数据
    async GetClsRoomDatas(data: GetClsRoomDatasParam) {
        const res = await get(`${this.ServiceURL}/GetClsRoomDatas`, data);
        return res;
    },
    //获取教室数据树形
    async GetClsRoomTreeDatas(userid: string) {
        const res = await get(`${this.ServiceURL}/GetTreeForSortAndRange`, {userid});
        return res;
    },
    //删除录播资源
    async DeleteMaterial(data: DeleteMaterialParam) {
        const res = await get(`${this.ServiceURL}/DeleteMaterial`, data);
        return res;
    },
    //获取资源列表
    async GetMaterialByRTID(data: GetMaterialByRTIDParam) {
        const res = await get(`${this.ServiceURL}/GetMaterialByRTID`, data);
        return res;
    },
    async getRecServerURL() {
        const res = await get(`/${ORGTOKEN}/V4/Service/GetRecServerURL`, {});
        return res;
    }
}