
import { Popup, Toast, Button } from "vant";
import apiControl from "@/api/control";
import { Decrypt, FormatDate } from "@/scripts/utils";
import { reactive } from "@vue/reactivity";
import moment from "moment";
import MyPlayer from "@/components/Player/LivePlayer.vue";
import { Base64 } from "js-base64";
import protoRoot from "@/proto/proto";
import protobuf from "protobufjs";
import wx from "weixin-js-sdk";

export default {
  components: {
    [Popup.name]: Popup,
    MyPlayer,
  },
  setup() {
    const IOTCounts = reactive([
      { icon: "iconfont icon-duanluqi", using: 0, total: 0, name: "断路器" },
      {
        icon: "iconfont icon-dengguang",
        using: 0,
        total: 4,
        name: "灯光",
      },
      { icon: "iconfont icon-kaiguan", using: 0, total: 4, name: "调光器" },
      { icon: "iconfont icon-kongtiao", using: 0, total: 4, name: "空调" },
      { icon: "iconfont icon-fengshan", using: 0, total: 4, name: "风扇" },
      { icon: "iconfont icon-chuanglian", using: 0, total: 4, name: "窗帘" },
    ]);
    const IOT = reactive([]);
    const RBInfo = reactive([]);
    return {
      IOTCounts,
      IOT,
      RBInfo,
    };
  },
  data() {
    return {
      sourceMenu: [
        {
          name: "老师",
        },
        {
          name: "学生",
        },
        {
          name: "课件",
        },
      ],
      terminalMoreMenu: [
        {
          title: "一键控制",
          children: ["上课", "下课"],
        },
        {
          title: "开关控制",
          children: ["面板锁", "讲台锁", "中控警报", "门禁"],
        },
        {
          title: "投影仪控制",
          children: ["投影仪一", "投影仪二"],
        },
        {
          title: "视频输入",
          children: [
            "睿终端-主",
            "睿终端-副",
            "PC-HDMI",
            "PC-VGA",
            "扩展一",
            "扩展二",
            "笔记本HDMI",
            "笔记本VGA",
          ],
        },
        {
          title: "视频输出",
          children: ["全部", "大屏一", "大屏二", "录播互动"],
        },
      ],
      className: "",
      sourceActive: 0,
      internetActive: 0,
      terminalActive: 0,
      environmentMoreShow: false,
      terminalMoreShow: false,
      internetMoreShow: false,
      isHIK: false,
      time: 0,
      RecTimer: null,

      ClsRoomInfo: null,
      powerCustoms: null,
      TerminalInfo: null,
      Videos: [],
      curVideo: {},
      CurVideoUrl: "",
      PCInfo: null,
      sensorInfo: null, //环境参数
      IoTGWIP: "", // 网关IP
      Guard: null,
      SceneState: 0,

      TerminalRunInfo: null,
      AMXInput: 0,
      Powers: [],
      curId: "",
      curSerialNo: "",
      BuildingName: "",
      CampusesName: "",
    };
  },
  watch: {
    $route: {
      handler(val: any) {
        let self = this as any;
        if (val.query && val.path == "/classroom/detail") {
          self.className = val.query.className;
          self.curId = val.query.currId;
          self.curSerialNo = val.query.curSno;
          self.LoadData(val.query.currId);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    let self = this as any;
    self.$options.sockets.onmessage = (res: { data: string }) => {
      self.Listen(res);
    };
  },
  beforeUnmount() {
    let self = this as any;
    self.$options.sockets.onmessage = () => {
      return null;
    };
  },
  methods: {
    async LoadData(id: string) {
      let self = this as any;
      for (let index = 0; index < 6; index++) {
        self.IOTCounts[index].total = 0;
        self.IOTCounts[index].using = 0;
      }
      const res = (await apiControl.GetRoomInfo({
        id: id,
        needLive: "true",
      })) as any;
      if (res.Result && res.Data) {
        const infos = JSON.parse(res.Data);
        console.log(infos);
        self.RBInfo = infos.RBInfo;
        self.BuildingName = infos.BuildingName;
        self.CampusesName = infos.CampusesName;
        if (self.RBInfo && self.RBInfo.length) {
          self.RBInfo["REC"] = self.RBInfo.RECEstate;
          self.RBInfo["Live"] = self.RBInfo.LiveEstate;

          if (infos.HIKInfo) {
            self.isHIK = true;
            if (infos.HIKInfo.Result === 4) {
              if (infos.HIKInfo.RecodingStatus === 3) {
                self.RBInfo["REC"] = 0;
              } else if (infos.HIKInfo.RecodingStatus === 2) {
                self.RBInfo["REC"] = 1;
                self.time = infos.HIKInfo.RecrdingTime;
                self.startTime();
              } else if (res.HIKInfo.RecodingStatus === 0) {
                self.RBInfo["REC"] = 2;
                self.time = infos.HIKInfo.RecrdingTime;
                self.formatTime();
              }
            } else if (res.HIKInfo.Result === 5) {
              Toast.fail("连接录播主机失败");
            }
          }
        }

        self.ClsRoomInfo = infos.ClsRoomInfo;
        if (infos.PowerCustoms) {
          self.powerCustoms = infos.PowerCustoms;
        }

        //终端设备
        const strDevices = infos.Devices
          ? infos.Devices.replace(/\r/g, "")
              .replace(/\n/g, "")
              .replace(/\t/g, "")
          : null;
        const devices = infos.Devices ? JSON.parse(strDevices) : null;
        console.log(devices);
        if (devices) {
          const visions = devices.filter((d: any) => {
            return d.DeviceType === "4" || d.DeviceType === "5";
          });
          self.Videos = [];
          self.$nextTick(() => {
            self.Videos = visions;
            if (self.Videos.length) {
              self.curVideo = self.Videos[0];
              self.CurVideoUrl = `http://${self.$store.state.SrvConfigInfo.LiveHost}:${self.$store.state.SrvConfigInfo.LivePort}/Patrol/${self.curVideo.id}.flv`;
              // self.CurVideoUrl = `rtmp://${self.$store.state.SrvConfigInfo.LiveHost}/Patrol/${self.curVideo.id}`;
              console.log("curvideo", self.Videos);
            }
          });
          if (!self.$store.state.SrvConfigInfo.ShowNTO1 && visions) {
            await visions.forEach((video: any, index: number) => {
              if (
                video.DeviceType === "5" &&
                video.DeviceInfo.BrandCode === "FJRH"
              ) {
                visions.splice(index, 1);
              }
            });
          }

          self.PCInfo = devices.filter((d: any) => d.DeviceType === "2");
          console.log(self.TerminalInfo);
          self.GetTerminalInfo();
          self.TerminalInfo = devices.find((d: any) => d.DeviceType === "1");

          if (self.TerminalInfo) {
            const state = (await apiControl.GetCtrlState({
              key: self.TerminalInfo.DeviceSerialNo,
            })) as any;
            if (state.Result) {
              self.AnalysisTerminalInfo(state.Data);
              if (
                self.TerminalInfo &&
                state.Datas &&
                state.Datas.IP !== self.TerminalInfo.IP
              ) {
                self.GetTerminalInfo();
              }
            }
          }

          const iotBreaks = [] as any; // 断路器
          const iotLightSwitchs = [] as any; // 灯光面板
          const iotCurtains = [] as any; // 窗帘面板
          const iotFans = [] as any; // 风扇面板
          const iotDimmers = [] as any; // 调光器
          const iotAirs = [] as any; // 红外虚拟面板
          const EGuard = {} as any;

          // 添加485设备
          const iot485 = devices.filter((d: any) => d.DeviceType === "8");
          if (iot485 && iot485.length) {
            iot485.forEach((ele: any) => {
              switch (ele.ModelCode) {
                case "RH-DLQ-XY/485（2P）":
                case "RH-DLQ-XY/485（4P）":
                  iotBreaks.push({
                    devID: ele.id,
                    name: ele.Name,
                    ip: ele.DeviceInfo.IP,
                    Port: ele.DeviceInfo.Port,
                    channel: ele.DeviceInfo.Channel,
                    state: false,
                    online: false,
                  });
                  break;
                case "RH-CG-CRK/485": // 七合一
                  self.sensorInfo = {
                    tem: {
                      value: 0,
                      desc: "无数据",
                    },
                    hum: { value: 0 },
                    co: { value: 0 },
                    tvoc: { value: 0 },
                    pm25: { value: 0 },
                    ch20: { value: 0 },
                    pm10: { value: 0 },
                  };
                  break;
                case "RH-CG-VMS/485": // 三合一
                  self.sensorInfo = {
                    tem: {
                      value: 0,
                      desc: "无数据",
                    },
                    hum: { value: 0 },
                    lux: { value: 0 },
                  };
                  break;
                default:
                  break;
              }
            });
          }

          // 统计断路器数量
          if (iotBreaks.length > 0) {
            self.IOT["Breaks"] = iotBreaks;
            self.IOTCounts[0].total = iotBreaks.length;
          } else {
            self.IOT["Breaks"] = null;
          }

          // 网关设备
          const iotGWHost = devices.filter((d: any) => d.DeviceType === "40");
          if (iotGWHost && iotGWHost.length > 0) {
            self.IoTGWIP = iotGWHost[0].DeviceInfo.IP;
          }

          // 网关灯光面板设备
          const iotLS = devices.filter(
            (d: any) =>
              d.DeviceType === "41" ||
              d.DeviceType === "42" ||
              d.DeviceType === "43"
          );
          if (iotLS && iotLS.length > 0) {
            var lsCount = 0;
            iotLS.forEach((ele: any) => {
              var btn;
              switch (ele.DeviceType) {
                case "41":
                  btn = [{ state: false }];
                  lsCount += 1;
                  break;
                case "42":
                  btn = [{ state: false }, { state: false }];
                  lsCount += 2;
                  break;
                case "43":
                  btn = [{ state: false }, { state: false }, { state: false }];
                  lsCount += 3;
                  break;
              }

              iotLightSwitchs.push({
                devID: ele.id,
                serialNo: ele.DeviceSerialNo,
                devType: ele.DeviceType,
                name: ele.Name,
                online: false,
                switchs: btn,
              });
              self.IOTCounts[1].total = lsCount;
            });
          }

          // 统计灯光面板数量
          if (iotLightSwitchs.length > 0) {
            self.IOT["LightSwitchs"] = iotLightSwitchs;
          } else {
            self.IOT["LightSwitchs"] = null;
          }

          // 网关窗帘面板设备
          const iotCL = devices.filter((d: any) => d.DeviceType === "47");
          if (iotCL && iotCL.length > 0) {
            iotCL.forEach((ele: any) => {
              iotCurtains.push({
                devID: ele.id,
                serialNo: ele.DeviceSerialNo,
                devType: ele.DeviceType,
                name: ele.Name,
                online: false,
                state: 2,
              });
            });
          }

          // 统计窗帘面板数量
          if (iotCurtains.length > 0) {
            self.IOT["Curtains"] = iotCurtains;
            self.IOTCounts[5].total = iotCurtains.length;
          } else {
            self.IOT["Curtains"] = null;
          }

          // 网关风扇面板设备
          const iotFS = devices.filter((d: any) => d.DeviceType === "46");
          if (iotFS && iotFS.length > 0) {
            iotFS.forEach((ele: any) => {
              iotFans.push({
                devID: ele.id,
                serialNo: ele.DeviceSerialNo,
                devType: ele.DeviceType,
                name: ele.Name,
                online: false,
                state: 0,
              });
            });
          }

          // 统计风扇面板数量
          if (iotFans.length > 0) {
            self.IOT["Fans"] = iotFans;
            self.IOTCounts[4].total = iotFans.length;
          } else {
            self.IOT["Fans"] = null;
          }

          // 网关调光器设备
          const iotDim = devices.filter((d: any) => d.DeviceType === "44");
          if (iotDim && iotDim.length > 0) {
            iotDim.forEach((ele: any) => {
              iotDimmers.push({
                devID: ele.id,
                serialNo: ele.DeviceSerialNo,
                devType: ele.DeviceType,
                name: ele.Name,
                online: false,
                white: 0,
                yellow: 0,
              });
            });
          }

          // 统计调光器数量
          if (iotDimmers.length > 0) {
            self.IOT["Dimmers"] = iotDimmers;
            self.IOTCounts[2].total = iotDimmers.length;
          } else {
            self.IOT["Dimmers"] = null;
          }

          // 网关红外设备
          const iotInfraed = devices.filter(
            (d: any) => d.DeviceType === "45" || d.DeviceType === "48"
          );
          if (iotInfraed && iotInfraed.length > 0) {
            iotInfraed.forEach((ele: any) => {
              if (ele.DeviceInfo.SubAKA && ele.DeviceInfo.SubAKA.length > 0) {
                ele.DeviceInfo.SubAKA.forEach((sub: any) => {
                  if (sub.Type === 1) {
                    iotAirs.push({
                      devID: ele.id,
                      serialNo: ele.DeviceSerialNo,
                      devType: ele.DeviceType,
                      name: sub.Name,
                      code: sub.Code,
                      vpID: sub.ID,
                      b0: 24,
                      b1: 1,
                      b2: 2,
                      b3: true,
                      b4: 0,
                      b5: 0,
                      b6: 2,
                      online: false,
                    });
                  } else {
                    self.getCMDInfrared(sub.ModelID, ele.DeviceSerialNo);
                  }
                });
              }
            });
          }

          // 统计红外虚拟面板数量
          if (iotAirs.length > 0) {
            self.IOT["Airs"] = iotAirs;
            self.IOTCounts[3].total = iotAirs.length;
          } else {
            self.IOT["Airs"] = null;
          }

          const guards = devices.filter((d: any) => d.DeviceType === "12");
          if (guards && guards.length > 0) {
            guards.forEach((ele: any) => {
              EGuard.id = ele.id;
              EGuard.sNo = ele.DeviceSerialNo;
              EGuard.IP = ele.DeviceInfo.IP;
              EGuard.Port = ele.DeviceInfo.Port;
              EGuard.ModelCode = ele.ModelCode;
              EGuard.User = ele.DeviceInfo.User;
              EGuard.Pwd = ele.DeviceInfo.Pwd;
              EGuard.Gate1 = ele.DeviceInfo.Info.Gate_1;
              EGuard.Gate2 = ele.DeviceInfo.Info.Gate_2;
              EGuard.Gate3 = ele.DeviceInfo.Info.Gate_3;
              EGuard.Gate4 = ele.DeviceInfo.Info.Gate_4;
              EGuard.GState1 = 0;
              EGuard.GState2 = 0;
              EGuard.GState3 = 0;
              EGuard.GState4 = 0;
              EGuard.Model = "0";
            });
            EGuard.Model = EGuard.sNo.substr(0, 1);
            if (infos.EGuard) {
              EGuard.GState1 = infos.EGuard.Gate_1;
              EGuard.GState2 = infos.EGuard.Gate_2;
              EGuard.GState3 = infos.EGuard.Gate_3;
              EGuard.GState4 = infos.EGuard.Gate_4;
            }

            self.Guard = EGuard;
          }
        } else {
          self.Videos = [];
        }

        //环境参数
        if (infos.SensorInfo) {
          self.analysisSensor(JSON.parse(infos.SensorInfo).iOTSensors[0]);
        }

        // 处理断路器数据
        const airSwitch = infos.AirSwitchInfo
          ? JSON.parse(infos.AirSwitchInfo)
          : null;
        if (airSwitch) {
          self.analysisAirSwitch(airSwitch.iOTAirSwitches);
        }

        // 处理网关设备数据
        const iotGW = infos.IoTGWInfo ? JSON.parse(infos.IoTGWInfo) : null;
        self.anaylsisIoTData(iotGW);

        const resPower = (await apiControl.GetClsRoomPower({
          userid: JSON.parse(Decrypt(localStorage.getItem("UserInfo"))).UserID,
        })) as any;
        // console.log(self.TerminalInfo);
        // if (resPower && resPower.Result) {
        //   const findPower = resPower.Datas.find(
        //     (p:any) => p.ClsRoomID === this.$route.params.id
        //   );
        //   // console.log(this.$route.params.id, findPower);
        //   if (findPower && findPower.View > 0) {
        //     this.HasCtrlPower = findPower.Ctrl > 0;
        //     this.HasPTZPower = findPower.PTZ > 0;
        //   }
        // }
      }
    },
    Listen(res: { data: string }) {
      const $this = this as any;
      if (res) {
        const rawResponse = Base64.toUint8Array(res.data) as any;
        // console.log(rawResponse);
        const PBMessageResponse = protoRoot.lookup(`WS.WSMessage`) as any;
        const buf = protobuf.util.newBuffer(rawResponse) as any;
        const WSMessage = PBMessageResponse.decode(buf) as any;
        // console.log(WSMessage);
        if (WSMessage.Type === 1001 && WSMessage.ID === $this.curSerialNo) {
          if (WSMessage.Parameters) {
            WSMessage.Parameters.forEach((element: any) => {
              if (element.PBTypeName) {
                const rawResponse = Base64.toUint8Array(
                  element.PB64StrValue
                ) as any;
                const PBMessageResponse = protoRoot.lookup(
                  `TerminalInfoOne.${element.PBTypeName}`
                ) as any;
                const buf = protobuf.util.newBuffer(rawResponse) as any;
                element["ReceiveStatus"] = PBMessageResponse.decode(buf) as any;
              }
            });
            if (
              WSMessage.Parameters[0].ReceiveStatus.ClsRoomID === $this.curId
            ) {
              $this.AnalysisTerminalInfo(WSMessage.Parameters[0].ReceiveStatus);
            }
          }
        }

        if (WSMessage.Type === 1002 && WSMessage.ID === $this.curId) {
          if (WSMessage.Parameters) {
            WSMessage.Parameters.forEach((element: any) => {
              if (element.PBTypeName) {
                const rawResponse = Base64.toUint8Array(
                  element.PB64StrValue
                ) as any;
                const PBMessageResponse = protoRoot.lookup(
                  `RBModel.${element.PBTypeName}`
                ) as any;
                const buf = protobuf.util.newBuffer(rawResponse) as any;
                element["ReceiveRBStatus"] = PBMessageResponse.decode(
                  buf
                ) as any;
              }
            });
            const rbInfo = WSMessage.Parameters[0].ReceiveRBStatus;
            if ($this.RBInfo.ClsRoomID === rbInfo.ClsRoomID && !$this.isHIK) {
              $this.RBInfo["REC"] = rbInfo.RECEstate;
              $this.RBInfo["Live"] = rbInfo.LiveEstate;

              let recDuration = "-";
              let liveDuration = "-";
              if (rbInfo.RecType === 1 && rbInfo.Model !== "RH-R3CI-REC") {
                if (rbInfo.LiveEstate === 1) {
                  const duration = moment.duration(
                    Number(moment().format("x")) -
                      Number(
                        moment(
                          FormatDate(
                            rbInfo.LiveStartTime,
                            "yyyy-MM-dd hh:mm:ss"
                          )
                        ).format("x")
                      )
                  );

                  liveDuration = `${duration.days()}天${duration.hours()}时${duration.minutes()}分${duration.seconds()}秒`;
                }

                if (rbInfo.RECEstate === 1) {
                  const duration = moment.duration(
                    Number(moment().format("x")) -
                      Number(
                        moment(
                          FormatDate(rbInfo.RecStartTime, "yyyy-MM-dd hh:mm:ss")
                        ).format("x")
                      )
                  );

                  recDuration = `${duration.days()}天${duration.hours()}时${duration.minutes()}分${duration.seconds()}秒`;
                }
              }

              $this.RBInfo["RecDuration"] = recDuration;
              $this.RBInfo["LiveDuration"] = liveDuration;
            }
          }
        }

        if (
          WSMessage.Type === 1010 &&
          WSMessage.ID === $this.curId &&
          $this.IOT.Breaks
        ) {
          if (WSMessage.Parameters) {
            WSMessage.Parameters.forEach((element: any) => {
              if (element.PBTypeName) {
                const rawResponse = Base64.toUint8Array(
                  element.PB64StrValue
                ) as any;
                const PBMessageResponse = protoRoot.lookup(
                  `IOTAirModel.${element.PBTypeName}`
                ) as any;
                const buf = protobuf.util.newBuffer(rawResponse) as any;
                element["UpdateAirSwitch"] = PBMessageResponse.decode(
                  buf
                ) as any;
              }
            });
            const info = WSMessage.Parameters[0].UpdateAirSwitch;
            if (info && info.iOTAirSwitches.length > 0 && $this.IOT.Breaks) {
              let onLine = 0;
              info.iOTAirSwitches.forEach((ele: any) => {
                $this.IOT.Breaks.forEach((bre: any, index: number) => {
                  if (ele.DeviceID === bre.devID) {
                    bre.state = ele.States === 1;
                    bre.online = true;
                  }
                  if (bre.state) {
                    onLine++;
                  }
                });
              });
              $this.IOTCounts[0].using = onLine;
            }
          }
        }

        if (WSMessage.Type === 1011 && WSMessage.ID === $this.curId) {
          if (WSMessage.Parameters) {
            WSMessage.Parameters.forEach((element: any) => {
              if (element.PBTypeName) {
                const rawResponse = Base64.toUint8Array(
                  element.PB64StrValue
                ) as any;
                const PBMessageResponse = protoRoot.lookup(
                  `IOTGWModel.${element.PBTypeName}`
                ) as any;
                const buf = protobuf.util.newBuffer(rawResponse) as any;
                element["UpdateIoTGWDevState"] = PBMessageResponse.decode(
                  buf
                ) as any;
              }
            });
            $this.anaylsisIoTData(WSMessage.Parameters[0].UpdateIoTGWDevState);
          }
        }

        if (
          WSMessage.Type === 1012 &&
          $this.Guard.sNo === WSMessage.ID.toString()
        ) {
          if (WSMessage.Parameters) {
            WSMessage.Parameters.forEach((element: any) => {
              if (element.PBTypeName) {
                const rawResponse = Base64.toUint8Array(
                  element.PB64StrValue
                ) as any;
                const PBMessageResponse = protoRoot.lookup(
                  `Gate.${element.PBTypeName}`
                ) as any;
                const buf = protobuf.util.newBuffer(rawResponse) as any;
                element["UpdateGateMagnetismState"] = PBMessageResponse.decode(
                  buf
                ) as any;
              }
            });
            const info = WSMessage.Parameters[0].UpdateGateMagnetismState;
            if ($this.Guard.sNo === info.SNo.toString()) {
              $this.Guard.GState1 = info.Gate_1;
              $this.Guard.GState2 = info.Gate_2;
              $this.Guard.GState3 = info.Gate_3;
              $this.Guard.GState4 = info.Gate_4;
            }
          }
        }
      }
    },
    // 获取终端信息
    async GetTerminalInfo() {
      let self = this as any;
      if (
        self.TerminalInfo &&
        (self.TerminalInfo.ModelCode === "CPC3600QH" ||
          self.TerminalInfo.ModelCode === "CPC3600JZ" ||
          self.TerminalInfo.ModelCode === "CPC3000QH" ||
          self.TerminalInfo.ModelCode === "CPC3600BG")
      ) {
        const res = (await apiControl.GetTerminalInfo({
          ip: self.TerminalInfo.DeviceInfo.IP,
          port: self.TerminalInfo.DeviceInfo.Port,
        })) as any;
        if (res && res.Datas) {
          const json = JSON.parse(res.Datas);
          self.TerminalRunInfo = json.result;
        } else {
          self.TerminalRunInfo = null;
        }
      } else {
        self.TerminalRunInfo = null;
      }
    },
    // 解析终端状态
    AnalysisTerminalInfo(json: any) {
      let self = this as any;
      self.Powers = [];
      if (self.TerminalInfo) {
        self.TerminalInfo.Info = json;
      }
      if (
        json &&
        json.SerialNo &&
        json.SerialNo === self.TerminalInfo.DeviceSerialNo
      ) {
        if (json.PowerInfos && json.PowerInfos.length > 0) {
          json.PowerInfos.forEach((element: any) => {
            let findP =
              self.Powers && self.Powers.length > 0
                ? self.Powers.find((p: any) => p.ID === element.ID)
                : null;
            const findCustom =
              self.powerCustoms && self.powerCustoms.length > 0
                ? self.powerCustoms.find(
                    (c: any) => c.PowerIndex === element.ID
                  )
                : null;
            if (!findP) {
              findP = {
                ID: element.ID,
                Value: element.Value,
                Name: findCustom ? findCustom.PowerName : element.Name,
              };
              if (findCustom) {
                findP.PCID = findCustom.PCID;
                // this.$set(findP, "PCID", findCustom.PCID);
              }
              if (!findP.Name.includes("幕布")) {
                self.Powers.push(findP);
              }
            } else {
              findP.Value = element.Value;
              // this.$set(findP, "Value", element.Value);
            }
            if (json.Brand === "JYD") {
              switch (findP.ID) {
                case 212: {
                  findP.icon = "iconfont icon_Projector";
                  // this.$set(findP, "icon", "iconfont icon_Projector");
                  switch (self.TerminalInfo.DeviceInfo.ModelCode) {
                    case "JYDH":
                    case "JYDM": {
                      // this.$set(findP, "CMD", "Power_Projector1_");
                      // this.$set(findP, "Name", "投影机一");
                      findP.CMD = "Power_Projector1_";
                      findP.Name = "投影机一";
                      break;
                    }
                    default: {
                      // this.$set(findP, "CMD", "Power_Projector_");
                      // this.$set(findP, "Name", "投影机");
                      findP.CMD = "Power_Projector_";
                      findP.Name = "投影机";
                      break;
                    }
                  }
                  break;
                }
                case 28: {
                  switch (self.TerminalInfo.DeviceInfo.ModelCode) {
                    case "JYDH":
                    case "JYDM": {
                      // this.$set(findP, "icon", "iconfont icon_screen");
                      // this.$set(findP, "CMD", "Power_Curtain1_");
                      // this.$set(findP, "Name", "幕布一");
                      findP.icon = "iconfont icon_screen";
                      findP.CMD = "Power_Curtain1_";
                      findP.Name = "幕布一";
                      break;
                    }
                    default: {
                      // this.$set(findP, "icon", "iconfont icon_pc");
                      // this.$set(findP, "CMD", "Power_PC_");
                      // this.$set(findP, "Name", "电脑");
                      findP.icon = "iconfont icon_pc";
                      findP.CMD = "Power_PC_";
                      findP.Name = "电脑";
                      break;
                    }
                  }
                  break;
                }
                case 26: {
                  // this.$set(findP, "icon", "iconfont icon_showcase");
                  // this.$set(findP, "CMD", "Power_Booth_");
                  // this.$set(findP, "Name", "展台");
                  findP.icon = "iconfont icon_showcase";
                  findP.CMD = "Power_Booth_";
                  findP.Name = "展台";
                  break;
                }
                case 25: {
                  switch (self.TerminalInfo.DeviceInfo.ModelCode) {
                    case "JYDH":
                    case "JYDM": {
                      // this.$set(findP, "icon", "iconfont icon_Projector");
                      // this.$set(findP, "CMD", "Power_Projector2_");
                      // this.$set(findP, "Name", "投影机二");
                      findP.icon = "iconfont icon_Projector";
                      findP.CMD = "Power_Projector2_";
                      findP.Name = "投影机二";
                      break;
                    }
                    default: {
                      // this.$set(findP, "icon", "iconfont icon_light");
                      // this.$set(findP, "CMD", "Power_Light_");
                      // this.$set(findP, "Name", "灯光");
                      findP.icon = "iconfont icon_light";
                      findP.CMD = "Power_Light_";
                      findP.Name = "灯光";
                      break;
                    }
                  }
                  break;
                }
                case 21: {
                  switch (self.TerminalInfo.DeviceInfo.ModelCode) {
                    case "JYDH":
                    case "JYDM": {
                      // this.$set(findP, "icon", "iconfont icon_screen");
                      // this.$set(findP, "CMD", "Power_Curtain2_");
                      // this.$set(findP, "Name", "幕布二");
                      findP.icon = "iconfont icon_screen";
                      findP.CMD = "Power_Curtain2_";
                      findP.Name = "幕布二";
                      break;
                    }
                    default: {
                      // this.$set(findP, "icon", "iconfont icon_TV");
                      // this.$set(findP, "CMD", "Power_TV_");
                      // this.$set(findP, "Name", "电视");
                      findP.icon = "iconfont icon_TV";
                      findP.CMD = "Power_TV_";
                      findP.Name = "电视";
                      break;
                    }
                  }
                  break;
                }
                case 23: {
                  switch (self.TerminalInfo.DeviceInfo.ModelCode) {
                    case "JYDH":
                    case "JYDM": {
                      // this.$set(findP, "icon", "iconfont icon_pc");
                      // this.$set(findP, "CMD", "Power_PC_");
                      // this.$set(findP, "Name", "电脑");
                      findP.icon = "iconfont icon_pc";
                      findP.CMD = "Power_PC_";
                      findP.Name = "电脑";
                      break;
                    }
                    default: {
                      // this.$set(findP, "icon", "iconfont icon_vcr");
                      // this.$set(findP, "CMD", "Power_VCR_");
                      // this.$set(findP, "Name", "录像机");
                      findP.icon = "iconfont icon_vcr";
                      findP.CMD = "Power_VCR_";
                      findP.Name = "录像机";
                      break;
                    }
                  }
                  break;
                }
                case 27: {
                  switch (self.TerminalInfo.DeviceInfo.ModelCode) {
                    case "JYDH":
                    case "JYDM": {
                      // this.$set(findP, "icon", "iconfont icon_light");
                      // this.$set(findP, "CMD", "Power_Light_");
                      // this.$set(findP, "Name", "灯光");
                      findP.icon = "iconfont icon_light";
                      findP.CMD = "Power_Light_";
                      findP.Name = "灯光";
                      break;
                    }
                    default: {
                      // this.$set(findP, "icon", "iconfont icon_DVD");
                      // this.$set(findP, "CMD", "Power_DVD_");
                      // this.$set(findP, "Name", "DVD");
                      findP.icon = "iconfont icon_DVD";
                      findP.CMD = "Power_DVD_";
                      findP.Name = "DVD";
                      break;
                    }
                  }
                  break;
                }
                case 24: {
                  switch (self.TerminalInfo.DeviceInfo.ModelCode) {
                    case "JYDH":
                    case "JYDM": {
                      // this.$set(findP, "icon", "iconfont icon_TV");
                      // this.$set(findP, "CMD", "Power_TV_");
                      // this.$set(findP, "Name", "电视");
                      findP.icon = "iconfont icon_TV";
                      findP.CMD = "Power_TV_";
                      findP.Name = "电视";
                      break;
                    }
                    default: {
                      // this.$set(findP, "icon", "iconfont icon_deck");
                      // this.$set(findP, "CMD", "Power_Deck_");
                      // this.$set(findP, "Name", "卡座");
                      findP.icon = "iconfont icon_deck";
                      findP.CMD = "Power_Deck_";
                      findP.Name = "卡座";
                      break;
                    }
                  }
                  break;
                }
                case 22: {
                  switch (self.TerminalInfo.DeviceInfo.ModelCode) {
                    case "JYDH":
                    case "JYDM": {
                      // this.$set(findP, "icon", "iconfont icon_Speaker");
                      // this.$set(findP, "CMD", "Power_Speaker_");
                      // this.$set(findP, "Name", "功放");
                      findP.icon = "iconfont icon_Speaker";
                      findP.CMD = "Power_Speaker_";
                      findP.Name = "功放";
                      break;
                    }
                    default: {
                      // this.$set(findP, "icon", "iconfont icon_smart_terminal");
                      // this.$set(findP, "CMD", "Power_Control_");
                      // this.$set(findP, "Name", "中控");
                      findP.icon = "iconfont icon_smart_terminal";
                      findP.CMD = "Power_Control_";
                      findP.Name = "中控";
                      break;
                    }
                  }
                  break;
                }
              }
            } else {
              switch (findP.ID) {
                // 展台
                case 28: {
                  // this.$set(findP, "icon", "iconfont icon_showcase");
                  findP.icon = "iconfont icon_showcase";
                  break;
                }
                // 显示器
                case 26: {
                  // this.$set(findP, "icon", "iconfont icon_light");
                  findP.icon = "iconfont icon_light";
                  break;
                }
                // PC
                case 13:
                case 25: {
                  // this.$set(findP, "icon", "iconfont icon_pc");
                  findP.icon = "iconfont icon_pc";
                  break;
                }
                // 投影机
                case 21:
                case 22:
                case 12: {
                  // this.$set(findP, "icon", "iconfont icon_Projector");
                  findP.icon = "iconfont icon_Projector";
                  break;
                }
                // 幕布
                case 11:
                case 24:
                case 23: {
                  // this.$set(findP, "icon", "iconfont icon_screen");
                  findP.icon = "iconfont icon_screen";
                  break;
                }
                // 电视
                case 27: {
                  // this.$set(findP, "icon", "iconfont icon_TV");
                  findP.icon = "iconfont icon_TV";
                  break;
                }
                // 扩展
                case 29:
                case 40: {
                  // this.$set(findP, "icon", "iconfont icon_Spare");
                  findP.icon = "iconfont icon_Spare";
                  break;
                }
                // 灯
                case 42: {
                  // this.$set(findP, "icon", "iconfont icon_light");
                  findP.icon = "iconfont icon_light";
                  break;
                }
                // 功放
                case 41: {
                  // this.$set(findP, "icon", "iconfont icon_Speaker");
                  findP.icon = "iconfont icon_Speaker";
                  break;
                }
                default: {
                  // this.$set(findP, "icon", "iconfont icon_Jack");
                  findP.icon = "iconfont icon_Jack";
                  break;
                }
              }
            }
          });
        }
      }
    },
    // 解析网关数据
    anaylsisIoTData(data: any) {
      let self = this as any;
      if (data) {
        if (data.DeviceList && data.DeviceList.length > 0) {
          self.IOTCounts[1].using = 0;
          self.IOTCounts[4].using = 0;
          self.IOTCounts[5].using = 0;
          self.IOTCounts[2].using = 0;
          data.DeviceList.forEach((ele: any) => {
            switch (ele.Type) {
              case "RH_1Switch":
                if (self.IOT.LightSwitchs && self.IOT.LightSwitchs.length > 0) {
                  self.IOT.LightSwitchs.forEach((ls: any) => {
                    if (ele.ID === ls.serialNo) {
                      ls.online = ele.State;
                      if (ele.on_1 != null) {
                        ls.switchs[0].state = ele.on_1;
                      } else {
                        ls.switchs[0].state = false;
                      }
                      if (ele.on_1) {
                        self.IOTCounts[1].using += 1;
                      }
                    }
                  });
                }
                break;
              case "RH_2Switch":
                if (self.IOT.LightSwitchs && self.IOT.LightSwitchs.length > 0) {
                  self.IOT.LightSwitchs.forEach((ls: any) => {
                    if (ele.ID === ls.serialNo) {
                      ls.online = ele.State;
                      if (ele.on_1 != null) {
                        ls.switchs[0].state = ele.on_1;
                      } else {
                        ls.switchs[0].state = false;
                      }
                      if (ele.on_2 != null) {
                        ls.switchs[1].state = ele.on_2;
                      } else {
                        ls.switchs[1].state = false;
                      }
                      if (ele.on_1) {
                        self.IOTCounts[1].using += 1;
                      }
                      if (ele.on_2) {
                        self.IOTCounts[1].using += 1;
                      }
                    }
                  });
                }
                break;
              case "RH_3Switch":
                if (self.IOT.LightSwitchs && self.IOT.LightSwitchs.length > 0) {
                  self.IOT.LightSwitchs.forEach((ls: any) => {
                    if (ele.ID === ls.serialNo) {
                      ls.online = ele.State;
                      if (ele.on_1 != null) {
                        ls.switchs[0].state = ele.on_1;
                      } else {
                        ls.switchs[0].state = false;
                      }
                      if (ele.on_2 != null) {
                        ls.switchs[1].state = ele.on_2;
                      } else {
                        ls.switchs[1].state = false;
                      }
                      if (ele.on_3 != null) {
                        ls.switchs[2].state = ele.on_3;
                      } else {
                        ls.switchs[2].state = false;
                      }
                      if (ele.on_1) {
                        self.IOTCounts[1].using += 1;
                      }
                      if (ele.on_2) {
                        self.IOTCounts[1].using += 1;
                      }
                      if (ele.on_3) {
                        self.IOTCounts[1].using += 1;
                      }
                    }
                  });
                }
                break;
              case "RH_Motor":
                if (self.IOT.Curtains && self.IOT.Curtains.length > 0) {
                  self.IOT.Curtains.forEach((cl: any) => {
                    if (ele.ID === cl.serialNo) {
                      cl.online = ele.State;
                      cl.state = ele.MotorState;
                    }
                    if (ele.MotorState !== 2) {
                      self.IOTCounts[5].using += 1;
                    }
                  });
                }
                break;
              case "RH_Fan":
                if (self.IOT.Fans && self.IOT.Fans.length > 0) {
                  self.IOT.Fans.forEach((fs: any) => {
                    if (ele.ID === fs.serialNo) {
                      fs.online = ele.State;
                      fs.state = ele.FanState;
                    }
                    if (ele.FanState !== 0) {
                      self.IOTCounts[4].using += 1;
                    }
                  });
                }
                break;
              case "RH_2PWM":
                if (self.IOT.Dimmers && self.IOT.Dimmers.length > 0) {
                  self.IOT.Dimmers.forEach((dim: any) => {
                    if (ele.ID === dim.serialNo) {
                      dim.online = ele.State;
                      dim.white = ele.white;
                      dim.yellow = ele.yellow;
                    }
                    if (ele.State) {
                      self.IOTCounts[2].using += 1;
                    }
                  });
                }
                break;
              case "RH_Infraed":
              case "SZInFRPT":
                if (self.IOT.Airs && self.IOT.Airs.length > 0) {
                  self.IOT.Airs.forEach((air: any) => {
                    if (ele.ID === air.serialNo) {
                      air.online = ele.State;
                      if (ele.VPStates && ele.VPStates.length > 0) {
                        ele.VPStates.forEach((vps: any) => {
                          if (air.vpID === vps.ID) {
                            air.b0 = vps.B0;
                            air.b1 = vps.B1;
                            air.b2 = vps.B2;
                            air.b3 = vps.B3;
                            air.b4 = vps.B4;
                            air.b6 = vps.B6;
                          }
                        });
                      }
                    }
                  });
                }
                break;
              default:
                break;
            }
          });
        }
        self.SceneState = data.SceneNum;
      }
    },
    // 解析断路器数据
    analysisAirSwitch(data: any) {
      let self = this as any;
      if (
        data &&
        data.length > 0 &&
        self.IOT.Breaks &&
        self.IOT.Breaks.length > 0
      ) {
        var onLine = 0;
        data.forEach((ele: any) => {
          self.IOT.Breaks.forEach((bre: any, index: number) => {
            if (ele.DeviceID === bre.devID) {
              bre.state = ele.States === 1;
              bre.online = true;
            }
            if (bre.state) {
              onLine++;
            }
          });
        });
        self.IOTCounts[0].using = onLine;
      }
    },
    //解析环境参数
    analysisSensor(data: any) {
      let self = this as any;
      switch (data.DevType) {
        case "RH-CG-VMS/485":
          self.sensorInfo = {
            tem: {
              value: Math.round(data.SensorTemperature),
              desc: data.SensorTemperatureLevelName,
            },
            hum: { value: self.toDecimal2NoZero(data.SensorHumidity) },
            lux: { value: self.toDecimal2NoZero(data.SensorLUX) },
          };
          break;
        case "RH-CG-CRK/485":
          self.sensorInfo = {
            tem: {
              value: Math.round(data.SensorTemperature),
              desc: data.SensorTemperatureLevelName,
            },
            hum: { value: self.toDecimal2NoZero(data.SensorHumidity) },
            co: { value: self.toDecimal2NoZero(data.SensorCO2) },
            tvoc: { value: self.toDecimal2NoZero(data.SensorTVOC) },
            pm25: { value: self.toDecimal2NoZero(data.SensorPM25) },
            ch20: { value: self.toDecimal2NoZero(data.SensorCH2O) },
            pm10: { value: self.toDecimal2NoZero(data.SensorPM10) },
          };
          break;
      }
    },
    // 保留两位小数  不保留0
    toDecimal2NoZero(x: number) {
      var f = Math.round(x * 100) / 100;
      var s = f.toString();
      return s;
    },
    // 获取自定义红外码
    async getCMDInfrared(modelID: string, serialNo: string) {
      let self = this as any;
      const res = (await apiControl.GetCMDByMID({
        modelID: modelID,
      })) as any;
      if (res.Result) {
        if (res.Datas && res.Datas.length > 0) {
          if (self.IOT.Customs && self.IOT.Customs.length > 0) {
            res.Datas.forEach((ele: any) => {
              self.IOT.Customs.push({
                name: ele.CMDName,
                code: ele.CMDContent,
                serialNo,
              });
            });
          } else {
            var iotCustoms = [] as any;
            res.Datas.forEach((ele: any) => {
              iotCustoms.push({
                name: ele.CMDName,
                code: ele.CMDContent,
                serialNo,
              });
            });
            self.IOT["Customs"] = iotCustoms;
          }
        }
      }
    },
    //控制终端
    async Control(cmd: string, value: string) {
      let self = this as any;
      if (cmd === "reset") {
        self.AMXInput = 0;
      }
      const res = (await apiControl.Control({
        userid: JSON.parse(Decrypt(localStorage.getItem("UserInfo"))).UserID,
        serialNo: self.TerminalInfo.DeviceSerialNo,
        ip: self.TerminalInfo.DeviceInfo.IP,
        port: self.TerminalInfo.DeviceInfo.Port,
        key: cmd,
        value: value === "On" ? "0" : value === "Off" ? "1" : value,
      })) as any;
      if (res.Result && res.Data === "True") {
        Toast.success("操作成功");
      } else {
        Toast.fail("操作失败");
      }
    },
    // 批量控制窗帘
    batchCL(action: number) {
      let self = this as any;
      if (self.IOT.Curtains && self.IOT.Curtains.length > 0) {
        self.IOT.Curtains.forEach((ele: any) => {
          if (ele.online) {
            self.sendIoT(self.IoTGWIP, ele.serialNo, 3, action, 0, 0, 0);
          }
        });
      }
    },
    // 批量控制调光器
    batchDim(type: string) {
      let self = this as any;
      if (self.IOT.Dimmers && self.IOT.Dimmers.length > 0) {
        if (type === "w") {
          self.sendAllDimIoT(
            JSON.parse(self.$route.params.row).classroomID,
            100,
            0
          );
        } else {
          self.sendAllDimIoT(
            JSON.parse(self.$route.params.row).classroomID,
            0,
            100
          );
        }
      }
    },
    // 控制全部调光器
    async sendAllDimIoT(clsid: string, white: number, yellow: number) {
      const res = (await apiControl.ControlIoTDimmer({
        sourcw: 1,
        clsID: clsid,
        white: white,
        yellow: yellow,
      })) as any;
      if (res.Info) {
        Toast.fail(res.Info);
      }
    },
    // 批量控制风扇
    batchFS(action: number) {
      let self = this as any;
      if (self.IOT.Fans && self.IOT.Fans.length > 0) {
        self.IOT.Fans.forEach((ele: any) => {
          if (ele.online) {
            self.sendIoT(self.IoTGWIP, ele.serialNo, 4, action, 0, 0, 0);
          }
        });
      }
    },
    // 批量控制灯光面板
    batchIoTLS(atcion: boolean) {
      let self = this as any;
      if (self.IOT.LightSwitchs && self.IOT.LightSwitchs.length > 0) {
        self.IOT.LightSwitchs.forEach((ele: any) => {
          if (ele.online) {
            switch (ele.devType) {
              case "41":
                self.sendIoT(
                  self.IoTGWIP,
                  ele.serialNo,
                  1,
                  atcion ? 0 : 1,
                  1,
                  0,
                  0
                );
                break;
              case "42":
                self.sendIoT(
                  self.IoTGWIP,
                  ele.serialNo,
                  1,
                  atcion ? 0 : 1,
                  1,
                  0,
                  0
                );
                self.sendIoT(
                  self.IoTGWIP,
                  ele.serialNo,
                  1,
                  atcion ? 0 : 1,
                  2,
                  0,
                  0
                );
                break;
              case "43":
                self.sendIoT(
                  self.IoTGWIP,
                  ele.serialNo,
                  1,
                  atcion ? 0 : 1,
                  1,
                  0,
                  0
                );
                self.sendIoT(
                  self.IoTGWIP,
                  ele.serialNo,
                  1,
                  atcion ? 0 : 1,
                  2,
                  0,
                  0
                );
                self.sendIoT(
                  self.IoTGWIP,
                  ele.serialNo,
                  1,
                  atcion ? 0 : 1,
                  3,
                  0,
                  0
                );
                break;
            }
          }
        });
      }
    },
    // 切换播放源
    ChangeSource(index: number) {
      let self = this as any;
      self.sourceActive = index;
      self.curVideo = self.Videos[index];
      self.CurVideoUrl = `http://${self.$store.state.SrvConfigInfo.LiveHost}:${self.$store.state.SrvConfigInfo.LivePort}/Patrol/${self.curVideo.id}.flv`;
      // self.CurVideoUrl = `rtmp://${self.$store.state.SrvConfigInfo.LiveHost}/Patrol/${self.curVideo.id}`;
    },
    // 发送面板 灯光 窗帘 风扇 控制指令
    async sendIoT(
      ip: string,
      id: string,
      type: number,
      action: number,
      ep: number,
      white: number,
      yellow: number
    ) {
      const res = (await apiControl.ControlGWDevice({
        sourcw: 1,
        ip: ip,
        id: id,
        type: type,
        action: action,
        ep: ep,
        white: white,
        yellow: yellow,
      })) as any;
      if (res.Info) {
        Toast.fail(res.Info);
      }
    },
    // 批量控制空调
    batchAir(action: number) {
      let self = this as any;
      if (self.IOT.Airs && self.IOT.Airs.length > 0) {
        self.IOT.Airs.forEach((ele: any) => {
          if (ele.online) {
            var original = false;
            if (ele.devType === "48") {
              original = true;
            }
            self.sendIoTAir(
              ele.vpID,
              self.IoTGWIP,
              ele.serialNo,
              ele.code,
              true,
              24,
              1,
              2,
              1,
              action,
              1,
              2,
              original
            );
          }
        });
      }
    },
    // 发送空调指令
    async sendIoTAir(
      vpID: string,
      ip: string,
      id: string,
      code: string,
      isCodeBank: boolean,
      b0: number,
      b1: number,
      b2: number,
      b3: number,
      b4: number,
      b5: number,
      b6: number,
      original: boolean
    ) {
      const res = (await apiControl.ControlInfraredAIR({
        sourcw: 1,
        vpID: vpID,
        ip: ip,
        id: id,
        code: code,
        isCodeBank: isCodeBank,
        b0: b0,
        b1: b1,
        b2: b2,
        b3: b3,
        b4: b4,
        b5: b5,
        b6: b6,
        original: original,
      })) as any;
      if (res.Info) {
        Toast.fail(res.Info);
      }
    },
    // 批量控制断路器
    batchAirSwitch(action: boolean) {
      let self = this as any;
      self.IOT.Breaks.forEach((ele: any) => {
        self.sendAirSwitch(
          ele.ip,
          ele.Port,
          ele.channel,
          !action,
          ele.devID,
          JSON.parse(self.$route.params.row).classroomID
        );
      });
    },
    // 发送断路器指令
    async sendAirSwitch(
      ip: string,
      Port: string,
      channel: string,
      state: boolean,
      devID: string,
      clsID: string
    ) {
      const res = (await apiControl.ControlSwitch({
        sourcw: 1,
        ip: ip,
        port: Port,
        channel: channel,
        action: state ? 0 : 1,
        deviceId: devID,
        clsId: clsID,
      })) as any;
      if (res.Info) {
        Toast.fail(res.Info);
      } else {
        Toast.success("操作成功");
      }
    },
    startTime() {
      let self = this as any;
      self.RecTimer = setInterval(self.timer, 1000);
    },
    timer() {
      let self = this as any;
      self.time++;
      self.formatTime();
    },
    formatTime() {
      let self = this as any;
      var s = self.showNum(self.time % 60);
      var m = self.showNum(Number(self.time / 60) % 60);
      var h = self.showNum(Number(self.time / 60 / 60));
      self.timeStr = h + ":" + m + ":" + s;
    },
    showNum(num: number) {
      if (num < 10) {
        return "0" + num;
      }
      return num;
    },
    linkTo() {
      let self = this as any;
      self.$router.push(
        `/classroom/setting?currId=${self.curId}&className=${self.className}&curSno=${self.curSerialNo}&pagetype=h5`
      );
    },
    viewLive() {
      let self = this as any;
      let rtmpUrl = `rtmp://${self.$store.state.SrvConfigInfo.LiveHost}/Patrol/${self.curVideo.id}`;
      console.log(rtmpUrl);
      (wx as any).miniProgram.navigateTo({
        url: `../play/play?currId=${self.curId}&className=${self.className}&curSno=${self.curSerialNo}&rtmpUrl=${rtmpUrl}&type=gk`,
      });
    },
  },
};
