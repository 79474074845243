
import {
  Search,
  DropdownMenu,
  DropdownItem,
  Checkbox,
  ActionSheet,
  Dialog,
  Toast,
  Loading,
} from "vant";
import apiRecord from "@/api/record";
import apiControl from "@/api/control";
import { Decrypt } from "@/scripts/utils";
import { Base64 } from "js-base64";
import protoRoot from "@/proto/proto";
import protobuf from "protobufjs";
import wx from "weixin-js-sdk";

export default {
  components: {
    [Search.name]: Search,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Checkbox.name]: Checkbox,
    [ActionSheet.name]: ActionSheet,
    [Loading.name]: Loading,
  },
  data() {
    return {
      terminalTypeList: ["全部", "在用", "空闲", "离线"],
      terminalType: 0,
      classList: [] as any,
      copyClassList: [],
      show: false,
      isShowAllSelect: false,
      loop: null,
      intervalCount: 0,
      allSelect: false,
      Actions: [
        {
          tip: "终端上课",
          name: "上课",
          cmd: "sceneOnClass",
          icon: "iconfont icon_CancelSelect",
          needOne: true,
        },
        {
          tip: "终端下课",
          name: "下课",
          cmd: "sceneOffClass",
          icon: "iconfont icon_bell",
          needOne: true,
        },
        {
          tip: "电子开锁",
          name: "电子开锁",
          cmd: "E_Lock",
          icon: "iconfont icon_EKey",
          needOne: true,
        },
        {
          tip: "开门",
          name: "开门",
          cmd: "Guard",
          icon: "iconfont icon_opendoor",
        },
        {
          tip: "面板锁定",
          name: "面板锁定",
          cmd: "lock",
          icon: "iconfont icon_lock",
        },
        {
          tip: "面板解锁",
          name: "面板解锁",
          cmd: "unlock",
          icon: "iconfont icon_key",
        },
        {
          tip: "刷新第三方终端状态",
          name: "刷新",
          cmd: "refresh",
          icon: "el-icon-refresh",
          needOne: false,
        },
        {
          tip: "打开PC电源",
          name: "打开PC电源",
          cmd: "Power_1",
          icon: "iconfont icon_powerOn",
        },
        {
          tip: "关闭PC电源",
          name: "关闭PC电源",
          cmd: "Power_0",
          icon: "iconfont icon_powerOff",
        },
      ],
      Powers: [],

      ListenTimer: null,
      IsHubOn: false,
      campusValues: 0,
      campusOptions: [{ text: "全部校区", value: 0, children: [] }],
      buildingValues: 0,
      buildingOptions: [{ text: "全部教学楼", value: 0, children: [] }],
      clsValues: 0,
      clsOptions: [{ text: "全部教室", value: 0 }],
      clsTypeValues: 0,
      clsTypeOptions: [{ text: "全部类型", value: 0 }],
      IsCtrlOP: true //是否具有操作权限
    };
  },
  watch: {
    allSelect(newVal: any) {
      let self = this as any;
      self.classList.forEach((item: any, index: any) => {
        self.classList[index].checked = newVal;
      });
    },
  },
  async mounted() {
    let self = this as any;
    self.getClsPower();
    await self.GetClsRoomDatas();
    await self.GetALLClsRoomTypes();
    await self.loadData();
    self.$options.sockets.onmessage = (res: { data: string }) => {
      self.Listen(res);
    };
  },
  methods: {
    //获取数据
    async loadData() {
      let self = this as any;
      const res = (await apiControl.GetAllRooms({
        userid: JSON.parse(Decrypt(localStorage.getItem("UserInfo"))).UserID,
      })) as any;
      if (res && res.Result) {
        let result = res.Data ? JSON.parse(res.Data) : null;
        if (result) {
          res.Datas = result.Datas;
          res.Datas2 = result.Datas2;
        }
        if (res.Datas) {
          res.Datas.forEach((room: any) => {
            const deviceInfo = room.DeviceInfo;

            const rbConfig = room.RBConfigInfo;

            const iotState = room.GWState ? 1 : 0;

            let terminal = null;

            if (deviceInfo) {
              terminal = deviceInfo.find((d: any) => d.DeviceType === "1");
            }
            if (terminal) {
              self.classList.push({
                state: 0,
                name: room.ClsRoomInfo.ClsRoomName,
                checked: false,
                campusID: room.ClsRoomInfo.CampusesID,
                buildingID: room.ClsRoomInfo.BuildingID,
                classroomID: room.ClsRoomInfo.ClsRoomID,
                clsRoomType: room.ClsRoomInfo.ClsRoomType,
                IOTState: iotState,
                Terminal: {
                  State: -1,
                  DeviceSerialNo: terminal.DeviceSerialNo,
                  DeviceInfo: terminal.DeviceInfo,
                  DifferIP: false,
                },
                RB: rbConfig ? { REC: -2, RECType: rbConfig.RECType } : null,
              });
            }
          });
        }

        const resState = (await apiControl.AllCtrlStates()) as any;
        if (resState.Result && resState.Data) {
          JSON.parse(resState.Data).forEach((ti: any) => {
            const findR = self.classList.find((r: any) => {
              return (
                r.classroomID === ti.ClsRoomID ||
                (r.Terminal &&
                  (r.Terminal.DeviceSerialNo === ti.SerialNo ||
                    r.Terminal.DeviceInfo.IP === ti.IP))
              );
            });
            if (findR) {
              if (!findR.Terminal) {
                findR.Terminal = {
                  State: -1,
                  DeviceSerialNo: ti.SerialNo,
                  DeviceInfo: {
                    IP: ti.IP,
                    Port: ti.Port,
                    ModelCode: ti.Model,
                    BrandCode: ti.Brand,
                  },
                  DifferIP: false,
                };
              }

              switch (ti.State) {
                case "Opened": {
                  findR.Terminal.State = 3;
                  break;
                }
                case "Opening": {
                  findR.Terminal.State = 2;
                  break;
                }
                case "ShuttingDown": {
                  findR.Terminal.State = 1;
                  break;
                }
                case "Shutdown": {
                  findR.Terminal.State = 0;
                  break;
                }
                case "OffLine": {
                  findR.Terminal.State = -1;
                  break;
                }
              }
            }
          });
        }
        if (res.Datas2 && res.Datas2.length > 0) {
          res.Datas2.forEach((element: any) => {
            self.classList.forEach((room: any) => {
              if (room.RB) {
                if (room.classroomID === element.ClsRoomID) {
                  room.RB.REC = element.RECEstate;
                }
              }
            });
          });
        }
        self.copyClassList = self.classList;

        const resPowers = (await apiControl.GetClsRoomPower({
          userid: JSON.parse(Decrypt(localStorage.getItem("UserInfo"))).UserID,
        })) as any;
        if (resPowers && resPowers.Result) {
          self.Powers = resPowers.Datas;
        }
      }
      self.GetScheduleDatas();
    },
    // 获取管控组权限
    async getClsPower() {
      let self = this as any;
      //1-查看 128-云台控制  256-终端控制  1024-广播
      const auth = [1, 128, 256, 1024];
      const ary = [] as any;
      const userid = JSON.parse(Decrypt(localStorage.getItem("UserInfo"))).UserID
      const res = (await apiControl.GetCoGByUserID({
        userid: userid
      })) as any;
      if (res) {
        const arr = res.Datas.map((element: any) =>{
          return parseInt(element.COPAuth)
        })
        const maxAuth = Math.max(...arr);
        auth.forEach((ele: any) => {
          const num = maxAuth & ele;
          if (auth.indexOf(num) > -1) {
            ary.push(num);
          }
        })
        self.IsCtrlOP = ary.indexOf(256) > -1 ? true : false
      }
    },
    //订阅监听状态
    Listen(res: { data: string }) {
      let self = this as any;
      if (res) {
        const rawResponse = Base64.toUint8Array(res.data) as any;
        const PBMessageResponse = protoRoot.lookup(`WS.WSMessage`) as any;
        const buf = protobuf.util.newBuffer(rawResponse) as any;
        const WSMessage = PBMessageResponse.decode(buf) as any;
        if (WSMessage.Type === 1000) {
          if (WSMessage.Parameters) {
            WSMessage.Parameters.forEach((element: any) => {
              if (element.PBTypeName) {
                const rawResponse = Base64.toUint8Array(
                  element.PB64StrValue
                ) as any;
                const PBMessageResponse = protoRoot.lookup(
                  `TerminalInfo.${element.PBTypeName}`
                ) as any;
                const buf = protobuf.util.newBuffer(rawResponse) as any;
                element["ReceiveALLStatus"] = PBMessageResponse.decode(
                  buf
                ) as any;
              }
            });
            self.classList.forEach((element: any) => {
              WSMessage.Parameters.forEach((data: any) => {
                if (
                  element.Terminal &&
                  element.classroomID === data.ReceiveALLStatus.ClsRoomID
                ) {
                  if (
                    data.ReceiveALLStatus.IP !== element.Terminal.DeviceInfo.IP
                  ) {
                    element.Terminal.DifferIP = true;
                  }

                  let isPcOpen = false;

                  if (data.ReceiveALLStatus.PowerInfos) {
                    data.ReceiveALLStatus.PowerInfos.forEach((de: any) => {
                      if (de.ID === 13 || de.ID === 25) {
                        if (de.ReceiveALLStatus === "On") isPcOpen = true;
                      }
                    });
                  }

                  element.Terminal.isPcOpen = isPcOpen;

                  element.Terminal.State = data.ReceiveALLStatus.State;

                  // if (element.RB) {
                  //   element.RB.REC = data.Value.RECState;
                  // }
                }
              });
            });
          }
        }

        if (WSMessage.Type === 1003) {
          if (WSMessage.Parameters) {
            WSMessage.Parameters.forEach((element: any) => {
              if (element.PBTypeName) {
                const rawResponse = Base64.toUint8Array(
                  element.PB64StrValue
                ) as any;
                const PBMessageResponse = protoRoot.lookup(
                  `ALLRHGWStatus.${element.PBTypeName}`
                ) as any;
                const buf = protobuf.util.newBuffer(rawResponse) as any;
                element["ReceiveALLRHGWStatus"] = PBMessageResponse.decode(
                  buf
                ) as any;
              }
            });
            self.classList.forEach((element: any) => {
              WSMessage.Parameters.forEach((data: any) => {
                if (
                  element.classroomID === data.ReceiveALLRHGWStatus.ClsRoomID
                ) {
                  element.IOTState = data.ReceiveALLRHGWStatus.State ? 1 : 0;
                }
              });
            });
          }
        }
      }
    },
    // 控制指令响应
    Control(action: any) {
      let self = this as any;
      if (action.cmd !== "refresh") {
        Dialog.confirm({
          title: "提示",
          message: `即将批量进行<b style='color:#F56C6C;'>${action.tip}</b>操作, 是否继续?`,
          confirmButtonColor: "#2f7dff",
          allowHtml: true,
        })
          .then(() => {
            if (action.cmd.indexOf("Power_") === 0) {
              self.DoControl("Power_13", action.cmd.substr(6, 1));
              self.DoControl("Power_25", action.cmd.substr(6, 1));
            } else {
              self.DoControl(action.cmd, "1");
            }
          })
          .catch(() => {
            // on cancel
          });
      } else {
        self.DoControl(action.cmd, "");
      }
    },
    // 执行控制
    async DoControl(cmd: string, value: string) {
      let self = this as any;
      const controls = [] as any;
      const Selections = [] as any;
      const match = self.classList.filter((item: any) => {
        return item.checked === true;
      });
      if (match.length) {
        match.forEach((item: any) => {
          Selections.push(item.classroomID);
        });
      }
      self.classList.forEach((element: any) => {
        if (
          (Selections.length <= 0 ||
            Selections.indexOf(element.classroomID) >= 0) &&
          (element.Terminal.State === 3 ||
            element.Terminal.State === 0 ||
            element.Terminal.State === 1 ||
            element.Terminal.State === 2 ||
            element.Terminal.State === -1)
        ) {
          let hasPower = false;
          if (self.Powers) {
            const findPower = self.Powers.find(
              (p: any) => p.ClsRoomID === element.classroomID
            );
            if (findPower && findPower.View > 0 && findPower.Ctrl > 0) {
              hasPower = true;
            }
          }
          if (hasPower) {
            controls.push({
              serialNo: element.Terminal.DeviceSerialNo,
              ip: element.Terminal.DeviceInfo.IP,
              port: element.Terminal.DeviceInfo.Port,
            });
          }
        }
      });

      if (controls && controls.length > 0) {
        const LZString = require("lz-string");
        const param = {
          userid: JSON.parse(Decrypt(localStorage.getItem("UserInfo"))).UserID,
          controls: LZString.compressToBase64(JSON.stringify(controls)),
          key: cmd,
          value: value,
        };
        const res = (await apiControl.BatchControl(param)) as any;
        if (res.Result && res.Data === "True") {
          self.show = false;
          self.isShowAllSelect = false;
          self.resetSelected();
          Toast.success("操作成功！");
        } else {
          Toast.fail("操作失败！");
        }
      }
    },
    //获取课表
    async GetScheduleDatas() {
      let self = this as any;
      const res = (await apiControl.GetCurrentDatas()) as any;
      self.classList.forEach((room: any) => {
        room.hasCls = false;
        room.Teachers = "";
        room.CourseName = "";
        room.Classes = "";
        if (res && res.Datas) {
          const find = res.Datas.find((d: any) => {
            return d.ClsRoomID === room.classroomID;
          });
          if (find) {
            room.hasCls = true;

            const thcs = find.ThcINFO ? JSON.parse(find.ThcINFO) : [];
            if (thcs) {
              thcs.forEach((t: any, i: number) => {
                room.Teachers += t.TeacherName;
                if (i < thcs.length - 1) room.Teachers += "，";
              });
            }

            room.CourseName = find.CourseName;

            const clses = find.ClassInfo ? JSON.parse(find.ClassInfo) : [];
            if (clses) {
              clses.forEach((c: any, i: number) => {
                room.Classes += c.ClassName;
                if (i < clses.length - 1) room.Classes += "，";
              });
            }
          }
        }
      });
    },
    //获取教室类型
    async GetALLClsRoomTypes() {
      let self = this as any;
      const res = (await apiRecord.GetALLClsRoomTypes()) as any;
      if (res.Result && res.Data) {
        const result = JSON.parse(res.Data)
        if(result && result.Datas) {
          result.Datas.forEach((item: any) => {
            self.clsTypeOptions.push({
              text: item.MetadataName,
              value: item.MetadataCode,
            });
          });
        }
      }
    },
    //获取教室信息
    async GetClsRoomDatas() {
      let self = this as any;
      // const res = (await apiRecord.GetClsRoomDatas({
      //   dogid: Decrypt(localStorage.getItem("OrgToken")),
      // })) as any;
      const userid = JSON.parse(Decrypt(localStorage.getItem("UserInfo"))).UserID
      const res = (await apiRecord.GetClsRoomTreeDatas(userid)) as any;
      if (res.Result) {
        console.log(JSON.parse(res.Data))
        res.Datas = JSON.parse(res.Data);
        res.Datas.forEach((item: any) => {
          self.campusOptions.push({
            text: item.Name,
            value: item.id,
            children: item.Children,
          });
        });
      }
    },
    async operate() {
      let self = this as any;
      self.isShowAllSelect = true;
      self.show = true;
    },
    campusChange(value: string) {
      let self = this as any;
      const match = self.campusOptions.filter((item: any) => {
        return item.value === value;
      });
      if (match.length) {
        self.buildingOptions = [{ text: "全部教学楼", value: 0, children: [] }];
        match[0].children.forEach((item: any) => {
          self.buildingOptions.push({
            text: item.Name,
            value: item.id,
            children: item.Children,
          });
        });
      }
      self.filter(self.terminalType);
    },
    buildingChange(value: string) {
      let self = this as any;
      const match = self.buildingOptions.filter((item: any) => {
        return item.value === value;
      });
      if (match.length) {
        self.clsOptions = [{ text: "全部教室", value: 0, children: [] }];
        match[0].children.forEach((item: any) => {
          self.clsOptions.push({
            text: item.Name,
            value: item.id,
          });
        });
      }
      self.filter(self.terminalType);
    },
    filter(index: number) {
      let self = this as any;
      self.terminalType = index;
      let match;
      if (self.clsTypeValues) {
        match = self.copyClassList.filter((item: any) => {
          return item.clsRoomType === self.clsTypeValues;
        });
      } else {
        match = self.copyClassList;
      }
      if (self.terminalType) {
        if (self.terminalType === 1) {
          match = self.copyClassList.filter((item: any) => {
            return item.Terminal.State === 3;
          });
        } else if (self.terminalType === 2) {
          match = self.copyClassList.filter((item: any) => {
            return (
              item.Terminal.State === 0 ||
              item.Terminal.State === 1 ||
              item.Terminal.State === 2
            );
          });
        } else {
          match = self.copyClassList.filter((item: any) => {
            return item.Terminal.State === -1;
          });
        }
      }
      if (self.campusValues) {
        match = match.filter((item: any) => {
          return item.campusID === self.campusValues;
        });
        if (self.buildingValues) {
          match = match.filter((item: any) => {
            return item.buildingID === self.buildingValues;
          });
          if (self.clsValues) {
            match = match.filter((item: any) => {
              return item.classroomID === self.clsValues;
            });
          }
        }
      }
      self.classList = match;
    },
    cancel() {
      let self = this as any;
      self.show = false;
      self.isShowAllSelect = false;
    },
    linkTo(row: any) {
      let self = this as any;
       const ua = navigator.userAgent.toLowerCase();
      const ORGTOKEN = Decrypt(localStorage.getItem("OrgToken"));
      const token = Decrypt(localStorage.getItem("Token"));
      let datas = {
        orgToken: ORGTOKEN,
        token: token,
      };
      // console.log(datas);
      // console.log(row.classroomID, row.name, row.Terminal.DeviceSerialNo);
      let params = encodeURIComponent(JSON.stringify(datas));
      if (ua.indexOf('micromessenger') == -1 || localStorage.getItem("appToken")) {
        self.$router.push(
          `/classroom/detail?currId=${row.classroomID}&className=${row.name}&curSno=${row.Terminal.DeviceSerialNo}`
        );
        return
      }
      if (ua.indexOf('micromessenger') > -1) {
        // 是微信端
        (wx as any).miniProgram.navigateTo({
          url: `../classroom/details?currId=${row.classroomID}&className=${row.name}&curSno=${row.Terminal.DeviceSerialNo}&params=${params}`,
        });
      }
      if(ua.indexOf('dingtalk') > -1){
        self.$router.push(`/ddMessage?frompage=classroom&currId=${row.classroomID}&className=${row.name}&curSno=${row.Terminal.DeviceSerialNo}&params=${params}`)
      }
    },
    resetSelected() {
      let self = this as any;
      self.classList.forEach((item: any) => {
        item.checked = false;
      });
    },
  },
  beforeUnmount() {
    let self = this as any;
    self.$options.sockets.onmessage = () => {
      return null;
    };
  },
};
