<template>
  <div class="guard-ctrl">
    <div v-if="guardInfo">
      <van-tabs v-model:active="active" class="tabs-ctrl">
        <van-tab :title="item + '号门'" v-for="item in guardInfo.DoorNumber" :key="item">
          <div class="guard-info">
            <div class="info-hd">
              <div class="left">
                <span class="name" v-if="guardInfo.Config && guardInfo.Config['name'+ item]">{{guardInfo.Config['name'+ item]}}</span>
                <span class="name" v-else>{{ item }}号门</span>
                <van-tag v-if="guardInfo.IsOnline" class="tag-state">在线</van-tag
                ><van-tag v-else class="tag-state off">离线</van-tag>
              </div>
              <!-- <div class="power"  v-if="guardInfo.HasPowerDisplay">电量：{{guardInfo.Battery}}</div> -->
            </div>
            <div class="state-wrap">
              <div
                class="door"
                :class="{
                  'out-line': !guardInfo.IsOnline,
                  'use-line': guardInfo.IsOnline && guardInfo['Gate' + item] === 1,
                  'close-line': guardInfo.IsOnline && guardInfo['Gate' + item] === 0
                }"
              >
                <template v-if="guardInfo['Gate' + item] === 1">
                  <div class="iconfont icon-cengmenkai"></div>
                  <div class="state">状态：门开</div>
                </template>
                <template v-if="guardInfo['Gate' + item] === 0">
                  <div class="iconfont icon-men"></div>
                  <div class="state">状态：门关</div>
                </template>
              </div>
            </div>
            <div class="btns-op" :class="{ center: !guardInfo.HasClosed }">
              <van-button class="btn-op" :disabled="!guardInfo.IsOnline" @click="operatCtrl(guardInfo, 0, item)"
                >开门</van-button
              >
              <van-button
                class="btn-op"
                v-if="guardInfo.HasClosed"
                :disabled="!guardInfo.IsOnline"
                @click="operatCtrl(guardInfo, 4, item)"
                >关门</van-button
              >
            </div>
            <div class="state-show">
              <van-button
                class="the-state"
                :class="{ active: guardInfo['CtrlType' + item] === 3 }"
                @click="operatCtrl(guardInfo, 3, item)"
                :disabled="!guardInfo.IsOnline"
                >常规</van-button
              >
              <van-button
                class="the-state"
                :class="{ active: guardInfo['CtrlType' + item] === 1 }"
                @click="operatCtrl(guardInfo, 1, item)"
                :disabled="!guardInfo.IsOnline"
                >常开</van-button
              >
              <van-button
                class="the-state"
                :class="{ active: guardInfo['CtrlType' + item] === 2 }"
                v-if="guardInfo.HasNormallyClosed"
                @click="operatCtrl(guardInfo, 2, item)"
                :disabled="!guardInfo.IsOnline"
                >常闭</van-button
              >
            </div>
          </div>
          <div class="warn-wrap">
            <div class="border-title">告警信息·{{ doorAlarms[item].length }}</div>
            <div class="warn-bd">
              <div class="item" v-for="log in doorAlarms[item]" :key="log.ID">
                <div class="left">
                  <div class="content">{{ log.reason }}</div>
                  <div class="time">{{ log.LogTime }}</div>
                </div>
                <van-button class="btn-handle" @click="handleWarn(log.ID, log.reason)">处理</van-button>
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab :title="dev.DeviceName" v-for="(dev, index) in IOTDoorLock" :key="index">
          <div class="guard-info">
            <div class="info-hd">
              <div class="left">
                <span class="name">{{ dev.DeviceName }}</span
                ><van-tag v-if="dev.IsOnline" class="tag-state">在线</van-tag
                ><van-tag v-else class="tag-state off">离线</van-tag>
              </div>
              <div class="power" v-if="dev.HasPowerDisplay">电量：{{dev.Battery}}%</div>
            </div>
            <div class="state-wrap">
              <div
                class="door"
                :class="{
                  'out-line': !dev.IsOnline,
                  'lock-close': dev.IsOnline
                }"
              >
                <template v-if="dev">
                  <div class="iconfont icon-cengmenkai" v-if="dev.CtrlType1 === 1"></div>
                  <div class="iconfont icon-men" v-else></div>
                  <!-- <div class="state" v-if="dev.Gate1 == 0">状态：未知</div>
                  <div class="state" v-if="dev.Gate1 == 1">状态：锁开</div>
                  <div class="state" v-if="dev.Gate1 == 2">状态：锁关</div> -->
                </template>
              </div>
            </div>
            <div class="btns-op">
              <van-button class="btn-op" :disabled="!dev.IsOnline" @click="CtrlGwDoor(dev, 1)"
                >开门</van-button
              >
              <van-button
                class="btn-op"
                :disabled="!dev.IsOnline"
                @click="CtrlGwDoor(dev, 2)"
                >常规</van-button
              >
            </div>
            <div class="state-show">
              <van-button
                class="the-state"
                :class="{ active: dev.CtrlType1 === 1 }"
                @click="CtrlGwDoor(dev, 3)"
                :disabled="!dev.IsOnline"
                >常开</van-button
              >
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <!-- 告警处理弹窗 -->
    <van-dialog
      v-model:show="handleWarnShow"
      title="告警处理"
      className="common-dialog warn-dialog"
      :showConfirmButton="false"
    >
      <div class="btn-close" @click="handleWarnShow = false">
        <van-icon name="cross" />
      </div>
      <div class="comment">
        <div class="name">处理意见</div>
        <van-field
          class="field-comment"
          v-model="comment"
          rows="5"
          autosize
          type="textarea"
          placeholder="请填写处理意见"
        />
      </div>
      <div class="btns-op">
        <van-button class="btn-solve" @click="doSolve(1)" :disabled="syncLoading">已解决</van-button>
        <van-button class="btn-repair" @click="doSolve(2)" :disabled="syncLoading">转报修</van-button>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import moment from "moment";
import apiControl from "@/api/control";
import { Toast, Tag, Button, Dialog, Field } from "vant";
export default {
  components: {
    [Tag.name]: Tag,
    [Button.name]: Button,
    [Field.name]: Field,
    [Dialog.Component.name]: Dialog.Component
  },
  props: {
    guardInfo: {
      type: Object
    },
    IOTDoorLock: {
      type: Array,
      default: () => []
    },
    Cid: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      active: 0,
      Lists: [],
      comment: "",
      handleWarnShow: false,
      syncLoading: false,
      logID: "",
      logReason: "",
      doorAlarms: {
        1: [],
        2: [],
        3: [],
        4: []
      }
    };
  },
  mounted() {
    // this.getGuardData();
    this.loadACAlarms();
  },
  methods: {
    // 获取教室门禁数据
    async getGuardData(id) {
      let arr = [];
      const res = await apiControl.GetEGuards({
        // clsroomid: "23041917050470191100869059362059",
        clsroomid: id ? id : this.Cid,
        // modelType: "'RH-MJZJ','WGQ70'"
        modelType: ""
      });
      this.loading = false;
      if (res && res.Result && Array.isArray(res.Datas) && res.Datas[0]) {
        res.Datas[0].model = res.Datas[0].DeviceSerialNo ? Number(res.Datas[0].DeviceSerialNo.substr(0, 1)) : 0;
        // 一间教室一组数据
        this.Lists = res.Datas.slice(0, 1);
      }
    },
    // 常规开关操作
    async operatCtrl(val, action, doorNum) {
      const res = await apiControl.CtrlGuard({
        id: val.DeviceID,
        brandcode: val.BrandCode,
        sNo: val.DeviceSerialNo,
        ip: val.IP,
        port: val.Port,
        action,
        doorNum
      });
      if (res) {
        if (res.Result) {
          setTimeout(async () => {
            const ctrlRes = await apiControl.GetEGuards({
              clsroomid: this.Cid,
              modelType: ""
            });
            if (ctrlRes && ctrlRes.Result && Array.isArray(ctrlRes.Datas)) {
              // if (this.Lists[0]) {
              //   this.Lists[0]["Gate" + doorNum] = ctrlRes.Datas[0]["Gate" + doorNum];
              // }
              Toast("操作成功");
            }
          }, 2000);
        } else {
          if (res.Info && Array.isArray(res.Info)) {
            Toast.fail(res.Info.join());
          } else {
            Toast.fail("操作失败！请稍后再试");
          }
        }
      }
    },
    //操作门锁控制
    async CtrlGwDoor(row, action) {
      const targets = [];
      targets.push({
        ip: row.IP,
        id: row.DeviceSerialNo
      })
      const res = await apiControl.ControlGWDoor(JSON.stringify(targets), action)
      if (res && res.Result) {
        Toast("操作成功");
      } else {
        Toast(res.Info);
      }
    },
    async loadACAlarms() {
      const res = await apiControl.GetACAlarms({
        roomIds: this.Cid,
        startTime: 0,
        endTime: moment(new Date()).format("YYYY-MM-DD"),
        key: "",
        pno: 1,
        psize: 100000,
        sdir: 1
      });
      if (res && res.Result && Array.isArray(res.Datas)) {
        res.Datas = res.Datas.filter((ele) => {
          return ele.SolverEstate === 0;
        });
        res.Datas.forEach((ele) => {
          if (ele.AlarmReason) {
            switch (ele.AlarmReason) {
              case 34:
                ele.reason = "胁迫报警";
                break;
              case 38:
                ele.reason = "强行闯入报警";
                break;
              case 39:
                ele.reason = "火警";
                break;
              case 40:
                ele.reason = "强制关门";
                break;
              case 41:
                ele.reason = "防盗报警";
                break;
              case 42:
                ele.reason = "烟雾煤气温度报警";
                break;
              case 43:
                ele.reason = "紧急呼救报警";
                break;
              case 101:
                ele.reason = "低电量报警";
                break;
              case 102:
                ele.reason = "密码错误报警";
                break;
              case 103:
                ele.reason = "防撬报警";
                break;
              case 104:
                ele.reason = "紧急胁迫报警";
                break;
              default:
                break;
            }
          }
        });
        this.doorAlarms[1] = res.Datas.filter((ele) => ele.DoorNO === 1);
        this.doorAlarms[2] = res.Datas.filter((ele) => ele.DoorNO === 2);
        this.doorAlarms[3] = res.Datas.filter((ele) => ele.DoorNO === 3);
        this.doorAlarms[4] = res.Datas.filter((ele) => ele.DoorNO === 4);
      }
    },
    async handleWarn(id, reason) {
      this.comment = "";
      this.logID = id;
      this.logReason = reason;
      this.handleWarnShow = true;
    },
    async doSolve(solverResult) {
      if (solverResult === 1) {
        this.syncLoading = true;
        const res = await apiControl.Solve({
          id: this.logID,
          solverResult,
          solverOpinion: this.comment
        });
        if (res && res.Result) {
          Toast("处理成功");
          await this.loadACAlarms();
        }
        this.handleWarnShow = false;
        this.syncLoading = false;
      } else {
        this.$router.push({
          name: "Create",
          query: {
            alarm: JSON.stringify({
              ID: this.logID,
              clsroomid: this.Cid,
              solverOpinion: this.comment,
              reason: this.logReason
            })
          }
        });
      }
    }
  }
};
</script>

<style lang="less">
@import url("../../../assets/styles/base.less");
.tabs-ctrl {
  .van-tabs__wrap {
    height: 40px;
  }
  .van-tabs__nav {
    border-radius: 6px 6px 0px 0px;
  }
  .van-tab {
    border-bottom: 1px solid #ddd;
  }
  .van-tabs__line {
    height: 2px;
    background: #2f7dff;
  }
  .van-tab__pane {
    height: calc(100vh - 45px - 44px - 15px - 40px - 20px);
    background-color: #fff;
    border-radius: 0 0 6px 6px;
    overflow-y: auto;
  }
}
.guard-ctrl {
  font-size: 14px;
  .van-button {
    border: none;
  }
}
.guard-info {
  padding: 10px 16px 30px;
  line-height: 1;
  .info-hd {
    display: flex;
    justify-content: space-between;
    .name {
      font-size: 16px;
    }
    .tag-state {
      color: @green-color;
      background: #dff4d4;
      border-radius: 4px;
      margin-left: 8px;
      &.off {
        color: #999999;
        background: #ddd;
      }
    }
  }
  .state-wrap {
    display: flex;
    justify-content: center;
    margin: 30px 0 25px;
    .door {
      width: 120px;
      height: 120px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #69c03b;
      background: #dff4d4;
      border-radius: 10px;
      &.out-line {
        background: #dddddd;
        color: #a2a2a2;
      }
      &.use-line {
        background: #dff4d4;
        color: #69c03b;
      }
      &.close-line {
        background: rgba(217, 158, 15, 0.2);
        color: #d99e0f;
      }
      &.lock-close{
        color: #2F7DFF;
        background: #EAF2FF;
      }
    }
    .iconfont {
      font-size: 60px;
    }
    .state {
      margin-top: 15px;
    }
  }
  .btns-op .btn-op,
  .the-state {
    height: 50px;
    color: #999999;
    background: #f3f4f8;
    border-radius: 4px;
    &.active {
      color: #ffffff;
      background: @primary-color;
    }
  }
  .btns-op {
    display: flex;
    justify-content: space-between;
    &.center {
      justify-content: center;
    }
    .btn-op {
      width: 150px;
    }
  }
  .state-show {
    margin-top: 15px;
    display: flex;
    justify-content: center;
  }
  .the-state {
    width: 105px;
  }
}
.warn-wrap {
  padding: 0 16px 20px;
  .border-title {
    position: relative;
    display: flex;
    align-items: center;
    height: 18px;
    line-height: 18px;
    padding-left: 9px;
    font-size: 16px;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      width: 4px;
      height: 18px;
      background-color: @primary-color;
    }
  }
  .warn-bd {
    padding-top: 3px;
    box-sizing: border-box;
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 14px 0 15px 0;
      border-bottom: 1px solid #ddd;
      .content {
        font-size: 16px;
      }
      .time {
        font-size: 12px;
        color: #999999;
        margin-top: 10px;
      }
      .btn-handle {
        width: 60px;
        height: 30px;
        border-radius: 4px;
        color: @primary-color;
        background: rgba(47, 125, 255, 0.1);
      }
    }
  }
}
.warn-dialog {
  .van-dialog__content {
    padding: 4px 15px 20px;
  }
  .comment {
    .van-field {
      margin-top: 15px;
      background: #edeeef;
    }
  }
  .btns-op {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }
  .btn-solve,
  .btn-repair {
    width: 140px;
    height: 40px;
    color: #fff;
    border-radius: 3px;
  }
  .btn-solve {
    background: #3d7eff;
  }
  .btn-repair {
    background: #ed3434;
  }
}
</style>
